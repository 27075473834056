
import _ from "lodash"
import axios from 'axios'

export const useFontStore = defineStore("font", {
  state: () => ({
    defaultFonts: [
      "Callem",
      "Great Vibes",
      "Dancing Script",
      "Cookie",
      "Parisienne",
    ],
    googleFonts: [],
  }),
  actions: {
    async initGoogleFonts() {

      await this.fetchGoogleFonts()
      this.registerFonts()

    },
    async fetchGoogleFonts(){

      const googleApi = `https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyApV8Umpabq0IulYtVW9xIYgIokI24ZoIs&sort=popularity`
      const response = await axios.get(googleApi)

      if(response.status === 200) {

        const fonts = response.data?.items || []
        const tmpList = _.take(fonts, 500)
        const sorted = _.sortBy(tmpList, ['family'])
        const familyList = _.map(sorted, (font: any) => font?.family)
        this.googleFonts = familyList
      }


    },

    registerFonts(){
      if(this.googleFonts?.length === 0) {
        return
      }


      const promiseList = []

      for (let index = 0; index < this.googleFonts.length; index++) {
        const fontFamily = this.googleFonts[index];
         // Construct the Google Fonts URL for the font family
        const fontUrl = `https://fonts.googleapis.com/css2?family=${encodeURIComponent(fontFamily)}&display=swap`;
          
        // Create a link element
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = fontUrl;

        promiseList.push(document.head.appendChild(link))
        
      }

      Promise.all(promiseList) 
      console.log('registered all fonts')
    }

    
  },
});
